<template>
  <div class="wraps-iim">
    <div class="topeach-policy-list">
      <span class="info-tips"> {{ xq.title }}</span>
    </div>
    <div class="topeach-policy-list">
      <span class="left-info-tips"
        ><span
          ><i class="el-icon-price-tag"></i
          ><span class="back" v-for="item in xq.label" :key="item.id">{{
            item.name
          }}</span> </span
        ><span
          ><i class="el-icon-location"></i>
          {{ xq.address ? xq.address.title : "无" }}</span
        ></span
      >
    </div>
    <ul class="policy-all-details">
      <li class="each-check-list">
        <span class="rg-publish-time">需求名称：</span
        ><span class="rg-de-box">{{ xq.title }}</span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">所属领域：</span
        ><span class="rg-de-box">{{
          xq.territory ? xq.territory.name : "无"
        }}</span>
      </li>

      <li class="each-check-list">
        <span class="rg-publish-time">需求类别：</span
        ><span class="rg-de-box">{{ xq.type ? xq.type.name : "无" }}</span>
      </li>

      <li class="each-check-list">
        <span class="rg-publish-time">紧急程度：</span
        ><span class="rg-de-box">
          {{ xq.urgency ? xq.urgency.name : "无" }}
        </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">预算经费：</span
        ><span class="rg-de-box">
          {{ xq.funds ? xq.funds : "无" }}
        </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">公司名称：</span
        ><span class="rg-de-box">
          {{ xq.company_name ? xq.company_name : "无" }}
        </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">联 系 人：</span
        ><span class="rg-de-box"> {{ xq.contacts ? xq.contacts : "无" }} </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">联系电话：</span
        ><span class="rg-de-box"> {{ xq.phone ? xq.phone : "无" }} </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">需求详情：</span
        ><span class="rg-de-box">
          {{ xq.content ? xq.content : "无" }}
        </span>
      </li>
    </ul>

    <div class="wrap-all-tipbox" v-show="isshow">
      <div class="left-info-title">选择接单专家</div>

      <el-form>
        <el-form-item>
          <el-checkbox-group v-model="resource" size="medium">
            <el-checkbox
              border
              @change="hangle(i.member_id)"
              v-for="i in erpert"
              :key="i.member_id"
              :label="i.member_id"
              >{{ i.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="xz">选择</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="text-align: right">
      <el-button type="primary" @click="success" :disabled="openIsDisabled"
        >服务结束</el-button
      >
    </div>
  </div>
</template>
<script>
import { wDemandXq, wDemand, Success, Order } from "@/common/js/api";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      id: "",
      xq: {},
      resource: [],
      isshow: false,
      erpert: [],
      openIsDisabled: false,
      resource1: "",
    };
  },
  watch: {
    item: {
      handler(val, oldVal) {
        if (this.item.dialogVisible == true && this.id != val.id) {
          this.id = val.id;
          this.isshow = val.isshow;
          this.wDemandXq(val.id);
        }
      },
      deep: true,
    },
  },
  created() {
    this.id = this.item.id;
    this.isshow = this.item.isshow;
    this.wDemandXq(this.id);
  },
  methods: {
    //  我发起的需求详情
    wDemandXq(id) {
      wDemandXq({
        id: id,
      })
        .then((res) => {
          // console.log(res);
          this.order(id);
          this.xq = res.data.data;
          if (this.xq.service == 3) {
            this.openIsDisabled = true;
          } else {
            this.openIsDisabled = false;
          }
        })
        .catch(() => {});
    },

    // 专家选择
    order(id) {
      Order({
        id: id,
      })
        .then((res) => {
          this.erpert = res.data;
          if (this.erpert.length == 0) {
            this.isshow = false;
          }
          for (let i = 0; i < this.erpert.length; i++) {
            if (this.erpert[i].is_grant == 2) {
              this.resource.push(this.erpert[i].member_id);
            }
            this.erpert[i]["name"] = this.erpert[i].experts[0].name;
          }
        })
        .catch(() => {});
    },
    hangle(val) {
      this.resource1 = val;
    },
    // 选择接单专家
    xz() {
      wDemand({
        member_id: this.resource1,
        demand_id: this.id,
      })
        .then((res) => {
          this.$message.success(res.msg);
        })
        .catch(() => {});
    },
    // 服务结束
    success() {
      Success({
        id: this.id,
      })
        .then((res) => {
          this.openIsDisabled = true;
          this.$message.success(res.msg);
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.topeach-policy-list {
  height: 34px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  .info-tips {
    color: #000;
    font-weight: 700;
  }
  .left-info-tips {
    display: flex;
    align-items: center;
    .back {
      color: #fff;
      background-color: #3a93eb;
      padding: 0 8px;
      border-radius: 10px;
    }
    span {
      line-height: 20px;
      margin-right: 4px;
    }
    i {
      color: #3a93eb;
    }
  }
}
.each-check-list {
  display: flex;
  margin-top: 12px;
  .rg-publish-time {
    color: #3a93eb;
    width: 80px;
  }
  .rg-de-box {
    width: 85%;
    line-height: 1.5;
  }
}
.left-info-title {
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0 10px;
  color: #010101;
}

.wrap-tiptit-box {
  line-height: 1.5;
  color: #010101;
}
</style>